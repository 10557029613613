<template>
  <div class="activity_container" :style="`background: ${info.bj_color}`">
    <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div>
    <div class="content">
      <div class="coupon" v-if="coupon.length">
        <div class="top">优惠券 <span>下单先领券、优惠多</span></div>
        <div class="cou_wrap">
          <div class="cou_item" v-for="item in coupon" :key="item.id">
            <img src="@/assets/img/zone/yhq.png" alt="" />
            <div class="box">
              <p class="pr">
                ￥<span>{{ item.money }}</span>
              </p>
              <div class="flex_1">
                <div class="p1 shenglue_1">{{ item.pcbz }}</div>
                <div style="font-size: 12px">{{ item.date_range }}</div>
                <div class="btn" @click="lingqu(item.id)">立即领取</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="item in zone" :key="item.id" :id="item.id">
        <div class="title">
          <div>{{ item.title }}</div>
          <div class="more" @click="handleGo(item)">更多 <i class="el-icon-arrow-right"></i></div>
        </div>

        <div class="goods_wrap">
          <div class="item" v-for="(itm, i) in item.list" :key="itm.id">
            <bigimg :item="itm" v-if="i < 5" />
          </div>
        </div>
      </div>
    </div>

    <!--左侧导航  -->
    <floornav :list="zone" />
  </div>
</template>

<script>
import bigimg from "@/components/goodsItem/bigImg.vue";
import floornav from "@/components/floornav.vue";
import { handleJump, getZoneInfo } from "@/utils";

export default {
  components: {
    bigimg,
    floornav,
  },
  data() {
    return {
      handleJump,
      zone: [],
      info: {},
      coupon: [],
    };
  },
  created() {
    this.$api("zone.getActivity").then((res) => {
      Promise.all(
        res.data.map(async (v) => {
          let list = [];
          await this.$api("goods.getGoods", { area_id: v.id }).then((res) => {
            list = res.data;
          });
          return { ...v, list };
        })
      ).then((res) => {
        this.zone = res;
      });
    });
    getZoneInfo(this.$route.query.value).then((res) => {
      this.info = res;
    });
    this.getCoupon();
  },
  methods: {
    getCoupon() {
      this.$api("zone.getActivityCoupon").then((res) => {
        this.coupon = res.data;
      });
    },
    handleGo(item) {
      console.log("活动参数----", item);
      this.$store.commit("CHANGE_PAGENAME", ["活动中心", item.title]);
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        path: "/index/allGoods",
        query: { value: `?area_id=${item.id}` },
      });
    },
    lingqu(id) {
      this.$api("zone.receiveActivityCoupon", { id }).then(() => {
        this.$message.success("领取成功");
        this.getCoupon();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.activity_container {
  background-size: cover;
  .img {
    width: 100%;
    height: 375px;
  }
  .content {
    padding-top: 30px;
  }
  .coupon {
    margin-bottom: 30px;
    width: 100%;
    padding: 20px;
    padding-bottom: 5px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    .top {
      font-size: 18px;
      font-weight: bold;
      color: #993715;
      padding-bottom: 20px;
      border-bottom: 1px solid #993715;
      span {
        font-size: 14px;
        font-weight: 400;
        margin-left: 20px;
      }
    }
    .cou_wrap {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      .cou_item {
        width: 360px;
        height: 126px;
        position: relative;
        margin-right: 25px;
        margin-bottom: 25px;
        img {
          position: absolute;
        }
        .box {
          width: 355px;
          height: 126px;
          position: absolute;
          display: flex;
          align-items: center;
          .pr {
            width: 140px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            span {
              font-size: 46px;
            }
          }
          .flex_1 {
            text-align: center;
            color: #ffffff;
            .p1 {
              font-size: 18px;
              margin-bottom: 6px;
            }
            .btn {
              cursor: pointer;
              width: 110px;
              height: 32px;
              background: #ffd673;
              border-radius: 16px;
              line-height: 32px;
              margin: 0 auto;
              font-size: 16px;
              font-weight: bold;
              color: #993715;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .title {
    width: 100%;
    background-color: #fff;
    height: 58px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    box-sizing: border-box;
    padding: 0 20px;
    // margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
  }
  .goods_wrap {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    // margin: 8px 0;
    margin-bottom: 10px;
    .item {
      margin-right: 12px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>
